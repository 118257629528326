const messages = {
    HomePage:{
        home1:'Hold Coin Dividend',
        home2:'Marketing Pull Plate',
        home3:'Burn Dividend',
        home4:'Ultimate Burning',
        home5:'Burning Dividend',
        home6:'Capital Reflux',
        home7:'Bottom Pool Burn',
        home8:'Top God of Wealth sells 100% orders, 30% goes to Holding Coin Dividend, 50% goes to Burning Dividend, 15% goes to community cultural marketing wallet, 10% of the funds are automatically purchased and burned every day when the wallet is triggered, 40% of NFT dividends are available for studios, supporting offline communities, and 5% for ecological construction',
        home9:'Protocol',
        home10:'Future',
        home11:'The brand-new boom314 Ultimate Burning Violence Dividend Plus Edition is coming. This is definitely the craziest money-making project in the history of cryptocurrency. It will completely change your perception of investment. Tokens will be violently deflated, and permanent destruction will become a thing of the past. There is no unlimited printing of money, and the haze of inflation is gone. The value of tokens only rises and never falls. The magic of deflation continues, and you, just buy or sell cash easily. The dividend is directly rolling in every minute you hold the token. You can get up to 30% of the huge dividend, which is simply picking up money. Fortune is like a draw, more exaggerated. Every transaction, 50% of the profit will be directly used to burn tokens, allowing you to increase the value of tokens. If you can hold the limited edition official NFT, you will unlock an additional 40% dividend package with blessings of wealth. The money grows crazily. The most important thing is that it builds a truly decentralized platform without the need for VPN, without the need for Swap transactions, without authorization, just a wallet, you have the initiative of the money game. Winners in life, fortune is prosperous, and making money is just right. Boom314 leads you to open a new era of investment, and writes a new chapter of wealth. Opportunities are not easy to come by. Now seize it firmly and never let go. You will be worry-free from now on, fight and become famous, and retire with one coin!',
        home12:'Chinese Telegram Group',
        home13:'Final Interpretation Right Belongs to',
        home14:'Slippage of 2 on trades, burning 0.5 hourly, and 12% daily.',
        home15:'2',
        home16:'0.5',
        home17:'12%',
        home18:'Total issuance of 210 million, fair launch',
        home19:'210 million',
        home20:'189 million Full network public God of Wealth contract private key full network public 21 million',
        home21:'189 million',
        home22:'21 million',
        home23:'600W + mit funds 80% for original bottom pool, 20% for black hole, 20% for marketing check contract',
        home24:'600W + mit',
        home25:'80%',
        home26:'20%',
        home27:'1500w',
        home28:'1500W open mit 3000 shares',
        home29:'(5000 coins per share)',
        home30:'0.1bnb per share (5000 coins per share), one wallet can hold up to 1bnb',
        home31:'There is no mit, the remaining parts is put into the black hole for extreme deflation of 2100W',
        home32:'Buying and selling process is the most convenient in history',
        home33:'Transactions are completely decentralized, no need for swap',
        home34:'No need for VPN to cross the wall, just one wallet',
        home35:'Solve the security pain points of swap authorization',
        home36:'Solve the pain points of defi robot clamps',
        home37:'Reshape the decentralization of cryptocurrency',
        home38:'dividend',
        home39:'Wallet Connected Successfully',
        home40:'Failed to Connect Wallet',
        home41:'Contact us',
    },
    mint:{
        mint1:'Opening Time',
        mint2:'Contract',
        mint3:'Copy',
        mint4:'',
        mint5:'Remaining',
        mint6:'Introduction',
        mint7:'Time',
        mint8:'Minimum Purchase',
        mint9:'To',
        mint10:'Convert BNB into the contract to complete Mint',
    },
    allPage:{
        allPage1:'Destroy',
        allPage2:'My Info',
        allPage3:'Dividend',
        allPage4:'Undistributed',
        allPage5:'Holdings',
        allPage6:'Top Brother',
        allPage7:'Community Creation',
        allPage8:'Community Name',
        allPage9:'Upload',
        allPage10:'Create',
        allPage11:'Create Community',
        allPage12:'Join',
        allPage13:'Completed',
        allPage14:'Pull Plate Description',
        allPage15:'Marketing Wallet 10% BNB is used to buy BOOM314 and inject into the black hole.',
        allPage16:'Pull Plate',
        allPage17:'Countdown to Pull Plate',
        allPage18:'Community Description',
        allPage19:'Private Key',
        allPage20:'Occupy',
        allPage21:'',
        allPage22:'Bonus destruction countdown',
        allPage23:'My community',
        allPage24:'Destroyed',
        allPage25:'Quantity',
        allPage26:'Other communities',
        allPage27:'Open for trading',
        allPage28:'address',
    }
}

export default messages
