const messages = {
    HomePage:{
        home1:'持币分红',
        home2:'营销拉盘',
        home3:'销毁分红',
        home4:'终极燃烧',
        home5:'燃烧分红',
        home6:'资金回流',
        home7:'底池烧毁',
        home8:'榜一财神爷卖单100%,30% 给持币分红,50%给燃烧分红,15%社区文化营销钱包,每天触发钱包的10%资金自动买入销毁,有工作室nft分红40%,助力线下社区,5%生态搭建',
        home9:'协议',
        home10:'未来',
        home11:'全新的boom314终极燃烧暴力分红plus版来袭,这绝对是加密货币史上最疯狂的赚钱好项目,它将彻底改变你对投资的认知,代币将暴力通缩,永久销毁成为过去式,没有无限制印钞,通胀阴霾不复存在,代币价值只升不跌,通缩魔力源源不断而你,只需简单买进或卖出现金就会直接滚滚而来,分分钟到手光是持有代币,就可获得高达30%的暴利分红这简直就是白捡钱,财富如抓阄送上门更夸张的是,每笔交易50%的收益将直接用于焚烧代币,让你手中代币与日俱增值如果你能持有限量版官方NFT那就再解锁额外40%分红大礼包加持财运,钞票自己长自己狂涨最重要的是,它构建了真正去中心化平台无需VPN翻墙,无需Swap交易,无需授权只需一个钱包,你就掌控了金钱游戏的主动权人生赢家,财运亨通,赚钱正当时boom314带你开启投资新纪元,书写财富新篇章机会来之不易,现在就狠狠抓住不放手,就会从此高枕无忧,一战成名,一币退休!',
        home12:'中文电报群',
        home13:'最终解释权归',
        home14:'买卖滑点2,每小时底池燃烧0.5每天单边燃烧12%',
        home15:'2',
        home16:'0.5',
        home17:'12%',
        home18:'总发行2.1亿,公平发射',
        home19:'2.1亿',
        home20:'1.89亿全网公开财神爷合约私钥全网公开2100W',
        home21:'1.89亿',
        home22:'2100W',
        home23:'600W + mit资金80%为原始底池打入黑洞0%流入营销可查合约',
        home24:'600W + mit',
        home25:'80%',
        home26:'20%',
        home27:'1500w',
        home28:'1500W公开mit3000份',
        home29:'(每份5000币)',
        home30:'0.1bnb一份(每份5000币),一个钱包最多1bnb',
        home31:'没有mit完剩下打入黑洞极致通缩2100W',
        home32:'买卖流程史上最便捷',
        home33:'交易完全去中心化,无需swap',
        home34:'不需要vpn翻墙,一个钱包就可以',
        home35:'解决swap授权的不安全痛点',
        home36:'解决defi机器人夹子的痛点',
        home37:'重塑加密货币的去中心化',
        home38:'分红',
        home39:'鏈接錢包成功',
        home40:'鏈接錢包失敗',
        home41:'聯繫我們',
    },
    mint:{
        mint1:'開盤時間',
        mint2:'合約',
        mint3:'複製',
        mint4:'已',
        mint5:'剩餘',
        mint6:'介紹',
        mint7:'時間',
        mint8:'購買最低',
        mint9:'至',
        mint10:'往合約裡面轉BNB完成Mint',
    },
    allPage:{
        allPage1:'銷毀',
        allPage2:'我的資訊',
        allPage3:'分紅',
        allPage4:'未分配',
        allPage5:'持幣',
        allPage6:'榜首大哥',
        allPage7:'社區創建',
        allPage8:'社區名稱',
        allPage9:'上傳',
        allPage10:'創建',
        allPage11:'建立社區',
        allPage12:'加入',
        allPage13:'已完成',
        allPage14:'拉盤說明',
        allPage15:'營銷錢包10%BNB用來購買BOOM314並打入黑洞。',
        allPage16:'拉盤',
        allPage17:'拉盤倒計時',
        allPage18:'社區說明',
        allPage19:'私鑰',
        allPage20:'佔比',
        allPage21:'已',
        allPage22:'销毁分红倒計時',
        allPage23:'我的社區',
        allPage24:'銷燬數量',
        allPage25:'購買數量',
        allPage26:'其他社區',
        allPage27:'已開盤',
        allPage28:'地址',
    }
}

export default messages
