import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/reset.css';
import i18n from "@/lang/index";
import { Button,Overlay,RollingText,Highlight,Popup, Slider,RadioGroup, Radio,Circle,Field, CellGroup,CountDown,Divider } from 'vant';
import 'vant/lib/index.css';
createApp(App)
.use(store)
.use(router)
.use(i18n)
.use(Button)
.use(Overlay)
.use(RollingText)
.use(Highlight)
.use(Popup)
.use(Slider)
.use(RadioGroup)
.use(Radio)
.use(Circle)
.use(Field)
.use(CellGroup)
.use(CountDown)
.use(Divider)
.mount('#app')
