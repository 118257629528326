import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/Home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: "/Home/HomePage",
        component: () => import("@/views/home/HomePage.vue"),
        name: 'HomePage'
      },
      {
        path: "/Home/DestroyPage",
        component: () => import("@/views/home/DestroyPage.vue"),
        name: 'DestroyPage'
      },
      {
        path: "/Home/MintPage",
        component: () => import("@/views/home/MintPage.vue"),
        name: 'MintPage'
      },
      {
        path: "/Home/MoneyPage",
        component: () => import("@/views/home/MoneyPage.vue"),
        name: 'MoneyPage'
      },
      {
        path: "/Home/PullPlatePage",
        component: () => import("@/views/home/PullPlatePage.vue"),
        name: 'PullPlatePage'
      },
      {
        path: "/Home/NftPage",
        component: () => import("@/views/home/NftPage.vue"),
        name: 'NftPage'
      },
      {
        path: "/Home",
        redirect: "/Home/HomePage"
      },
    ]
  },
  {
    path: "/",
    redirect: "/Home"
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
  
})


export default router
