import { createStore } from 'vuex'

export default createStore({
  state: {
    boomAddress: '0x190985a01BA6C15aCec0f132d4c3704c21858295',// boom代币合约地址
    Marketing: '0x9B989ECACDDbC135F8f4669A4445a5a978BeC47F',//营销钱包地址
    DividendThreshold: '0x23BFB78B0854E5983E0C9D93b6AD8603f44F5C1e',//持币分红合约
    BurnDividendTracker: '0x4Ea5a6d61e4CbDa444fa77F0Cd15bE97E52A184C',//燃烧分红合约
    firstOnTheListAddress: '0xEC63ab62860c8C3Ba0B65B68404106a20ec3547D',//榜一大哥地址
    firstOnTheListPrivate: '52321c9a4ae37db00d0f1400f6ad6cd123a62dba4ebc21c2ef7adc73c6192621',//榜一大哥私钥
    nftDividendTracker: '0x6344Ec9547ca1e277D955E9D4f1Bd1a3337Ef18d',//NFT分红合约
    pantime:1714997880000//开盘时间（毫秒）
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
